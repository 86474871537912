// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-daily-deals-friday-js": () => import("./../../../src/pages/daily-deals/friday.js" /* webpackChunkName: "component---src-pages-daily-deals-friday-js" */),
  "component---src-pages-daily-deals-index-js": () => import("./../../../src/pages/daily-deals/index.js" /* webpackChunkName: "component---src-pages-daily-deals-index-js" */),
  "component---src-pages-daily-deals-monday-js": () => import("./../../../src/pages/daily-deals/monday.js" /* webpackChunkName: "component---src-pages-daily-deals-monday-js" */),
  "component---src-pages-daily-deals-saturday-js": () => import("./../../../src/pages/daily-deals/saturday.js" /* webpackChunkName: "component---src-pages-daily-deals-saturday-js" */),
  "component---src-pages-daily-deals-sunday-js": () => import("./../../../src/pages/daily-deals/sunday.js" /* webpackChunkName: "component---src-pages-daily-deals-sunday-js" */),
  "component---src-pages-daily-deals-thursday-js": () => import("./../../../src/pages/daily-deals/thursday.js" /* webpackChunkName: "component---src-pages-daily-deals-thursday-js" */),
  "component---src-pages-daily-deals-tuesday-js": () => import("./../../../src/pages/daily-deals/tuesday.js" /* webpackChunkName: "component---src-pages-daily-deals-tuesday-js" */),
  "component---src-pages-daily-deals-wednesday-js": () => import("./../../../src/pages/daily-deals/wednesday.js" /* webpackChunkName: "component---src-pages-daily-deals-wednesday-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-my-store-js": () => import("./../../../src/pages/my-store.js" /* webpackChunkName: "component---src-pages-my-store-js" */)
}

